<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('offers:customerRating')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6 ">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.devicesAmount',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.devicesAmount')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.devicesLocation',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.devicesLoc')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.websiteOpinion',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.websiteOpinion')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.socialMedia',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.socialMedia')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.emailFormat',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.emailFormat')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.customerShareCapital',
                                    ''
                                )
                            "
                            :label="
                                $t('offers:departments.customerShareCapital')
                            "
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getCRMDetails,
                                    'evaluation.scopeActivities',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.scopeActivities')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getCRMDetails, 'evaluation.industry', '')
                            "
                            :label="$t('offers:departments.industry')"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <Separator :text="$t('offers:summary')" />
                            </v-col>
                        </v-row>
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getCRMDetails, 'evaluation.summary', '')
                            "
                            :label="$t('offers:departments.summary')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div class="d-flex justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        v-if="edit()"
                        small
                        @click="editDeploys()"
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ 'Edycja oceny potencjału' }}</span>
            </v-tooltip>
        </div>
        <Modal
            :title="'Edycja oceny potencjału'"
            :open="open"
            :height="'520'"
            v-on:close="closeModal()"
        >
            <AEContent slot="AEContent" />
            <Buttons
                :type="'CRM_EVALUATION'"
                @closeModal="open = false"
                :action="'edit'"
                slot="buttons"
            />
        </Modal>
    </v-container>
</template>

<script>
import AEContent from '../Details/CRMEvaluationModal.vue'
import Buttons from '../Details/DetailsButtonsSaveEdit.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {},
    components: {
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
        }
    },

    computed: {
        ...mapGetters(['getCRMDetails']),
    },
    methods: {
        ...mapMutations(['setCRMModal']),
        ...mapActions(['fetchCRMRecord']),
        edit() {
            return this.checkPermissions('UPDATE')
        },

        async editDeploys() {
            this.open = true
            await this.fetchCRMRecord({ id: this.getCRMDetails._id })
            this.setCRMModal()
        },
        closeModal() {
            this.open = false
        },
    },
}
</script>
<style></style>
