<template>
    <v-container>
        <Header
            :registryStatus="getCRMStatuses"
            :recordIndex="getCRMDetails.index"
            :registryTitle="getCRMRegistry.name"
            :registryUrl="'crm'"
            :actualStatus="getCRMDetails.status"
            :avatarData="getCRMDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="'Edycja'"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        :type="'INFORMATION'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="editCRM()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Edytuj</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CRMEvents from '../../../components/Registries/CRM/Tabs/Details/CRMEvents.vue'
import Information from '../../../components/Registries/CRM/Tabs/Details/Information.vue'
import AEContent from './../../../components/Registries/CRM/Tabs/Details/InformationModal.vue'
import Buttons from './../../../components/Registries/CRM/Tabs/Details/DetailsButtonsSaveEdit.vue'
import CRMEvaluation from '../../../components/Registries/CRM/Tabs/Details/CRMEvaluation.vue'
import CRMRaport from '../../../components/Registries/CRM/Tabs/Details/CRMRaport.vue'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        CRMEvents,
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            items: [
                {
                    name: 'Zdarzenia',
                    id: 'events',
                    icon: 'mdi-pencil-circle-outline',
                    component: CRMEvents,
                },
                {
                    name: 'Ocena potencjału',
                    id: 'evaluation',
                    icon: 'mdi-star-outline',
                    component: CRMEvaluation,
                },
                {
                    name: 'Raport',
                    id: 'raport',
                    icon: 'mdi-book-open-outline',
                    component: CRMRaport,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getCRMStatuses',
            'getCRMRegistry',
            'getCRMDetails',
            'getProfileDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'clearRepresentativesInput',
            'CLEAR_CRM_MODAL',
            'setCRMModal',
        ]),
        ...mapActions(['fetchCRMRecord', 'updateCRMStatus']),
        changeStatus(status) {
            this.updateCRMStatus({ status, id: this.getCRMDetails._id })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editCRM() {
            this.open = true
            await this.fetchCRMRecord({ id: this.getCRMDetails._id })
            this.setCRMModal()
        },
        closeModal() {
            this.open = false
            this.CLEAR_CRM_MODAL()
            this.fetchCRMRecord({ id: this.getCRMDetails._id })
        },
    },
    async beforeRouteEnter(to, from, next) {
        const routeId = to.params.id
        try {
            const check = await store.dispatch('checkUserCanEnterToCRM', {
                routeId,
            })
            if (check) {
                store.dispatch('fetchCRMRecord', { id: to.params.id, next })
            } else {
                throw new Error('Brak dostępu')
            }
        } catch (error) {
            next({
                path: 'crm',
                replace: true,
            })
            store.commit('setSnackbars', {
                type: 'error',
                text: 'Brak dostępu',
            })
        }
    },
}
</script>
