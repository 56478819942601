<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="!validationResult"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        type: {
            type: String,
        },
        action: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(['getValidationSteps']),
        validationResult: {
            get() {
                if (this.type === 'INFORMATION') {
                    const validationResultStepOne =
                        this.getValidationSteps
                            .editInformationValidationStepOne || false
                    const validationResultStepThree =
                        this.getValidationSteps
                            .editInformationValidationStepThree || false

                    return validationResultStepOne && validationResultStepThree
                }
                if (this.type === 'CRM_EVALUATION') {
                    // TODO IF NEEDED IN FUTURE
                    return true
                }
                if (this.type === 'CRM_REPORT') {
                    // TODO IF NEEDED IN FUTURE
                    return true
                }
            },
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createCRM', 'editCRM', 'fetchCRMRecord']),
        async save() {
            this.disabled = true
            let success = await this.createCRM()
            if (success) {
                this.$emit('closeModal')
                this.fetchCRMRecord({ id: this.$route.params.id })
            }
            this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.editCRM({ id: this.$route.params.id })

            if (success) {
                this.$emit('closeModal')
                this.fetchCRMRecord({ id: this.$route.params.id })
            }
            this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
