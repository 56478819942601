var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.getCRMDetails.contractor.name)+" ")]),_c('div',{staticClass:"left-items-list"},[_c('span',{staticClass:"header"},[_vm._v(_vm._s(`Adres`)+":")]),_c('span',[_vm._v(_vm._s(_vm.$get(_vm.getCRMDetails, 'contractor.city', ''))+", "+_vm._s(_vm.$get( _vm.getCRMDetails, 'contractor.postCode', '' )))]),_c('span',[_vm._v("ul. "+_vm._s(_vm.$get(_vm.getCRMDetails, 'contractor.street', ''))+" "+_vm._s(_vm.$get( _vm.getCRMDetails, 'contractor.houseNumber', '' ))),(
                                    _vm.$get(
                                        _vm.getCRMDetails,
                                        'contractor.apartmentNumber',
                                        ''
                                    )
                                )?_c('span',[_c('span',[_vm._v("/"+_vm._s(_vm.$get( _vm.getCRMDetails, 'contractor.apartmentNumber', '' )))])]):_vm._e()])]),_c('div',{staticClass:"left-items-list"},[_c('span',{staticClass:"header"},[_vm._v(_vm._s(`Tytuł:`))]),_c('span',[_vm._v(" "+_vm._s(_vm.$get(_vm.getCRMDetails, 'title', '')))])]),_c('div',{staticClass:"left-items-list mb-5"},[_c('span',{staticClass:"header"},[_vm._v(_vm._s(`Lead:`))]),_c('span',[_vm._v(_vm._s(_vm.convertBooleanToYesOrNo( _vm.$get(_vm.getCRMDetails, 'leadBoolean', '') )))])]),_c('div',{staticClass:"participants-container"},[_c('span',{staticClass:"header"},[_vm._v(_vm._s(`Uczestnicy:`))]),_c('div',{staticStyle:{"overflow-y":"auto","height":"120px"}},_vm._l((_vm.getCRMDetails.CRMParticipants),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"participants-inner"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.lastname))]),(
                                            _vm.checkParticipantIsClientGuardian(
                                                item._id
                                            )
                                        )?_c('span',{staticStyle:{"color":"#445d75"}},[_vm._v("- opiekun klienta")]):_vm._e()])])}),0)])])],1)],1),_c('v-col',{staticClass:"px-10 mt-5",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","readonly":"","value":_vm.$get(_vm.getCRMDetails, 'offerType.field', ''),"label":'Przedmiot'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'prognosis.field', ''),"label":'Rokowanie'}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'forecasting', ''),"label":'Prognozowanie'}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'threats', ''),"label":'Zagrożenia'}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'currentSupplier', ''),"label":'Obecny dostawca'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"mt-10",attrs:{"readonly":"","dense":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'finalEvaluationOfTheProcess',
                                ''
                            ),"label":'Ocena końcowa procesu',"no-resize":"","rows":"1","auto-grow":""}})],1)],1)],1),_c('v-col',{staticClass:"pl-5 mt-5",attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{attrs:{"offset":"1","cols":"10"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'customerType.field', ''),"label":'Pochodzenie kontrahenta'}})],1),_c('v-col',{attrs:{"offset":"1","cols":"10"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'sourceOfAcquisition', ''),"label":'Źródło pozyskania'}})],1),_c('v-col',{attrs:{"offset":"1","cols":"10"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.$get(_vm.getCRMDetails, 'evaluation.summary', ''),"label":'Ocena potencjału'}})],1),_c('v-col',{attrs:{"offset":"1","cols":"9"}},[_c('v-text-field',{attrs:{"readonly":"","dense":"","value":_vm.presentPreviousProcessWithTheClientDate(
                                _vm.getCRMDetails.previousProcessWithTheClient
                            ),"label":'Poprzedni proces z klientem'}})],1),(
                        _vm.presentPreviousProcessWithTheClientDate(
                            _vm.getCRMDetails.previousProcessWithTheClient
                        ) !== ''
                    )?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openAskAccessModal()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-edit ")])]}}],null,false,1820444094)},[_c('span',[_vm._v("Poproś o dostęp")])])],1):_vm._e()],1)],1)],1),_c('ConfirmModal',{attrs:{"width":'700px',"openConfirm":_vm.openAskAccessModalBoolean},on:{"close-confirm":_vm.closeAskAccessModal}},[_c('div',{attrs:{"slot":"main"},slot:"main"},[_c('AskAcessModalContent')],1),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text mx-2",attrs:{"color":"#FF9900","width":"100","height":"40"},on:{"click":function($event){return _vm.closeAskAccessModal()}}},[_vm._v(_vm._s(_vm.$t('global:no')))]),_c('v-btn',{staticClass:"white--text mx-2",attrs:{"width":"100","height":"40","color":"secondary"},on:{"click":function($event){return _vm.confirmAccess(
                            _vm.getCRMDetails.previousProcessWithTheClient
                        )}}},[_vm._v(_vm._s(_vm.$t('global:yes')))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }