<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getCRMErrors.evaluationStepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >Raport</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-5" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            class="mb-3"
                                            :text="this.action"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <!-- WYBÓR PRACOWNIKA -->
                                        <v-select
                                            class="hidden-data"
                                            :items="
                                                getCRMDetails.CRMParticipants
                                            "
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.worker
                                            "
                                            :label="`Pracownik`"
                                            item-value="_id"
                                            return-object
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    selection.item.lastname
                                                }}</span>
                                            </template>
                                            <template v-slot:item="item">
                                                <span>{{
                                                    item.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    item.item.lastname
                                                }}</span>
                                            </template></v-select
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4"
                                        ><v-select
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.deviceState
                                            "
                                            :items="
                                                getRegistryDictionaries.crmReportDeviceSate
                                                    ? getRegistryDictionaries.crmReportDeviceSate
                                                    : []
                                            "
                                            label="Stan urządzenia"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-combobox
                                            label="Model"
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.model
                                            "
                                            @keyup="
                                                searchModel($event.target.value)
                                            "
                                            :items="models"
                                            return-object
                                            :item-text="comboBoxText"
                                            item-value="_id"
                                            variant="solo"
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4"
                                        ><v-select
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem
                                                    .subscriptionType
                                            "
                                            :items="
                                                getRegistryDictionaries.subscriptionType
                                                    ? getRegistryDictionaries.subscriptionType
                                                    : []
                                            "
                                            label="Rodzaj"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select
                                            :label="`FTTR`"
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.fttrValue
                                            "
                                            return-object
                                            :items="[
                                                'Tak',
                                                'Nie',
                                                'Nie dotyczy',
                                            ]"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row
                                    >
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem
                                                    .contractNumber
                                            "
                                            label="Numer umowy/FV"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-select
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem
                                                    .durationOfTheContract
                                            "
                                            :items="
                                                getRegistryDictionaries.durationOfTheContractDictionary
                                                    ? getRegistryDictionaries.durationOfTheContractDictionary
                                                    : []
                                            "
                                            label="Czas trwania umowy"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-select
                                            :label="`Jest aneksem`"
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.isAnnex
                                            "
                                            return-object
                                            :items="[
                                                'Tak',
                                                'Nie',
                                                'Nie dotyczy',
                                            ]"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem
                                                    .blankingContract
                                            "
                                            label="Aneksuje umowę"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="
                                                getCRMNewReportItemModal
                                                    .crmReportItem.comments
                                            "
                                            label="Uwagi"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    props: ['action'],
    computed: {
        ...mapGetters([
            'getCRMNewReportItemModal',
            'getCRMErrors',
            'getCRMRegistry',
            'getProfileDetails',
            'getCRMStep',
            'getRegistryDictionaries',
            'getCRMModal',
            'getCRMDetails',
        ]),
    },
    data() {
        return {
            models: [],
        }
    },
    methods: {
        ...mapActions([]),
        comboBoxText: item => `${item.manufacturer} ${item.model}`,
        async searchModel(search) {
            if (search.length > 2) {
                let response = await new this.Rest('GET', 'models')
                    .select()
                    .where({ isDeleted: false })
                    .search(['model'], search)
                    .send()

                this.models = response.data.items
            } else this.models = []
        },
    },
}
</script>

<style></style>
