<template>
    <div class="pa-5 confirmModal">
        <v-card-title class="d-flex justify-center pa-2"
            >Potwierdzenie zamknięcia sprzedaży</v-card-title
        >
        <div class="d-flex justify-center py-5">
            <img
                width="100"
                height="100"
                src="@/assets/img/global/warning.svg"
                alt="warning"
            />
        </div>

        <v-card-text class="d-flex justify-center pa-2"
            >Potwierdź, że chcesz zamknąć sprzedaż. Będzie ona zaraportowana do
            bieżącego miesiąca i nie będzie można jej już zmienić.</v-card-text
        >
    </div>
</template>
<script>
export default {
    i18nOptions: { namespaces: ['global'] },
}
</script>
