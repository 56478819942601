<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ getCRMDetails.contractor.name }}
                        </p>
                        <div class="left-items-list">
                            <span class="header">{{ `Adres` }}:</span>
                            <span
                                >{{
                                    $get(getCRMDetails, 'contractor.city', '')
                                }},
                                {{
                                    $get(
                                        getCRMDetails,
                                        'contractor.postCode',
                                        ''
                                    )
                                }}</span
                            >
                            <span
                                >ul.
                                {{
                                    $get(getCRMDetails, 'contractor.street', '')
                                }}
                                {{
                                    $get(
                                        getCRMDetails,
                                        'contractor.houseNumber',
                                        ''
                                    )
                                }}<span
                                    v-if="
                                        $get(
                                            getCRMDetails,
                                            'contractor.apartmentNumber',
                                            ''
                                        )
                                    "
                                    ><span
                                        >/{{
                                            $get(
                                                getCRMDetails,
                                                'contractor.apartmentNumber',
                                                ''
                                            )
                                        }}</span
                                    >
                                </span>
                            </span>
                        </div>
                        <div class="left-items-list">
                            <span class="header">{{
                                `Pochodzenie klienta:`
                            }}</span>

                            <span>{{
                                $get(getCRMDetails, 'customerType.field', '')
                            }}</span>
                        </div>
                        <div class="left-items-list">
                            <span class="header">{{ `Lead:` }}</span>
                            <span>{{
                                convertBooleanToYesOrNo(
                                    $get(getCRMDetails, 'leadBoolean', '')
                                )
                            }}</span>
                        </div>
                        <div class="left-items-list">
                            <span class="header">{{ `Przedmiot:` }}</span>
                            <span>{{
                                $get(getCRMDetails, 'offerType.field', '')
                            }}</span>
                        </div>
                        <div class="left-items-list">
                            <span class="header">{{ `Rokowanie:` }}</span>
                            <span>{{
                                $get(getCRMDetails, 'prognosis.field', '')
                            }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="pa-5 mt-2">
                <div class="events-icons-container">
                    <span>
                        <v-tooltip top>
                            <template
                                v-slot:activator="{
                                    on,
                                }"
                            >
                                <v-icon
                                    @click="openNewEventModal"
                                    class="new-event-icon"
                                    size="20"
                                    v-on="on"
                                    color="secondary"
                                    >mdi-plus-box</v-icon
                                >
                            </template>
                            <span>Dodaj zdarzenie</span>
                        </v-tooltip>
                    </span>
                    <span>
                        <v-tooltip top>
                            <template
                                v-slot:activator="{
                                    on,
                                }"
                            >
                                <v-avatar
                                    @click="resetTable"
                                    class="D-avatar-icon"
                                    size="16"
                                    color="#445D75"
                                    v-on="on"
                                    ><span class="P-text">R</span></v-avatar
                                >
                            </template>
                            <span>Resetuj</span>
                        </v-tooltip>
                    </span>
                    <span>
                        <v-tooltip top>
                            <template
                                v-slot:activator="{
                                    on,
                                }"
                            >
                                <v-avatar
                                    @click="findPageWithEventClosestCurrentDate"
                                    class="P-avatar-icon"
                                    size="16"
                                    color="#FFA63F"
                                    v-on="on"
                                    ><span class="P-text">P</span></v-avatar
                                >
                            </template>
                            <span>Wyszukaj najbliższe zdarzenie</span>
                        </v-tooltip>
                    </span>
                </div>

                <div class="event-container">
                    <v-data-table
                        :headers="headers"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        :items="getCRMEventsData"
                        hide-default-footer
                        :item-class="itemRowBackground"
                        @page-count="pageCount = $event"
                    >
                        <template v-slot:item.event="props">
                            <span>
                                <div class="column--event">
                                    <div>{{ props.item.eventType.field }}</div>
                                    <div class="column--event--secondary">
                                        {{
                                            $moment(
                                                props.item.createdAt
                                            ).format(`DD/MM/YYYY`)
                                        }}
                                    </div>
                                    <div>
                                        <div class="column--event--secondary">
                                            {{
                                                $moment(
                                                    props.item.createdAt
                                                ).format(`HH:mm`)
                                            }}
                                        </div>
                                        <div class="worker-container mt-1">
                                            <span>{{
                                                $get(
                                                    props.item,
                                                    'createdBy.name',
                                                    ''
                                                )
                                            }}</span>
                                            <span class="pl-1">
                                                {{
                                                    $get(
                                                        props.item,
                                                        'createdBy.lastname',
                                                        ''
                                                    )
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </template>
                        <template v-slot:item.statuses="props">
                            <div class="column--event--icons">
                                <span v-if="props.item.nextEventDate">
                                    <div v-if="checkIconType_P(props.item)">
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                }"
                                            >
                                                <v-avatar
                                                    color="orange"
                                                    size="16"
                                                    v-on="on"
                                                >
                                                    <span class="white--text"
                                                        >P</span
                                                    >
                                                </v-avatar>
                                            </template>
                                            <span>{{
                                                $moment(
                                                    props.item.nextEventDate
                                                ).format('YYYY/MM/DD')
                                            }}</span>
                                        </v-tooltip>
                                        <span class="planning_text ml-1">{{
                                            $moment(
                                                props.item.nextEventDate
                                            ).format('YYYY/MM/DD')
                                        }}</span>
                                    </div>
                                    <div v-if="!checkIconType_P(props.item)">
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                }"
                                            >
                                                <v-avatar
                                                    color="grey"
                                                    size="16"
                                                    v-on="on"
                                                >
                                                    <span class="white--text"
                                                        >P</span
                                                    >
                                                </v-avatar>
                                            </template>
                                            <span>{{
                                                $moment(
                                                    props.item.nextEventDate
                                                ).format('YYYY/MM/DD')
                                            }}</span>
                                        </v-tooltip>
                                        <span class="planning_text ml-1">{{
                                            $moment(
                                                props.item.nextEventDate
                                            ).format('YYYY/MM/DD')
                                        }}</span>
                                    </div>
                                    <div class="nextEvent-container">
                                        <span
                                            v-if="
                                                checkNextEventType(
                                                    props.item
                                                ) == 'Telefon'
                                            "
                                            class="nextEventType"
                                            ><v-icon small
                                                >mdi-phone</v-icon
                                            ></span
                                        >
                                        <span
                                            v-if="
                                                checkNextEventType(
                                                    props.item
                                                ) == 'Spotkanie'
                                            "
                                            class="nextEventType"
                                            ><v-icon small
                                                >mdi-human-male-female</v-icon
                                            ></span
                                        >
                                        <span
                                            v-if="
                                                checkNextEventType(
                                                    props.item
                                                ) == 'Email'
                                            "
                                            class="nextEventType"
                                            ><v-icon small
                                                >mdi-email</v-icon
                                            ></span
                                        >
                                        <div>
                                            <span class="planning_text ml-3">{{
                                                props.item.nextEventTime
                                            }}</span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.offerBoolean="props">
                            <span v-if="props.item.offerBoolean">
                                <v-tooltip bottom>
                                    <template
                                        v-slot:activator="{
                                            on,
                                        }"
                                    >
                                        <v-avatar
                                            color="secondary"
                                            size="16"
                                            v-on="on"
                                        >
                                            <span class="white--text">O</span>
                                        </v-avatar>
                                    </template>
                                    <span>Oferta</span>
                                </v-tooltip>
                            </span>
                        </template>
                        <template v-slot:item.createdBy="props">
                            <span>
                                <div class="column--representative">
                                    <div class="nameAndLastname">
                                        <span>
                                            <span
                                                v-if="
                                                    checkRepresentativeType(
                                                        props.item
                                                    ) == 'Wpływ na decyzję'
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <span
                                                            v-on="on"
                                                            class="dot decisionIMPACT"
                                                        ></span>
                                                    </template>
                                                    <span
                                                        >Wpływ na decyzję</span
                                                    >
                                                </v-tooltip>
                                            </span>

                                            <span
                                                v-if="
                                                    checkRepresentativeType(
                                                        props.item
                                                    ) == 'Brak informacji'
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <span
                                                            v-on="on"
                                                            class="dot decisionNOINFO"
                                                        ></span>
                                                    </template>
                                                    <span>Brak informacji</span>
                                                </v-tooltip>
                                            </span>

                                            <span
                                                v-if="
                                                    checkRepresentativeType(
                                                        props.item
                                                    ) == 'Decyzyjny'
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <span
                                                            v-on="on"
                                                            class="dot decisionON"
                                                        ></span>
                                                    </template>
                                                    <span>Decyzyjny</span>
                                                </v-tooltip>
                                            </span>

                                            <span
                                                v-if="
                                                    checkRepresentativeType(
                                                        props.item
                                                    ) == 'Niedecyzyjny'
                                                "
                                            >
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <span
                                                            v-on="on"
                                                            class="dot decisionOFF"
                                                        ></span>
                                                    </template>
                                                    <span>Niedecyzyjny</span>
                                                </v-tooltip>
                                            </span>

                                            {{
                                                $get(
                                                    props.item,
                                                    'representative[0].name',
                                                    ''
                                                )
                                            }}
                                            {{
                                                $get(
                                                    props.item,
                                                    'representative[0].lastname',
                                                    ''
                                                )
                                            }}
                                        </span>
                                        <div class="position">
                                            {{
                                                $get(
                                                    props.item,
                                                    'representative[0].position',
                                                    ''
                                                )
                                            }}
                                        </div>
                                        <div class="email mt-1">
                                            <v-icon x-small>mdi-at</v-icon
                                            ><span>{{
                                                $get(
                                                    props.item,
                                                    'representative[0].email',
                                                    ''
                                                )
                                            }}</span>
                                        </div>
                                        <div class="phone mt-1">
                                            <v-icon x-small>mdi-phone</v-icon>
                                            <span>{{
                                                $get(
                                                    props.item,
                                                    'representative[0].phoneNumber',
                                                    ''
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </template>
                        <template v-slot:item.description="props">
                            <span>
                                <div>
                                    <div class="description-event">
                                        {{ props.item.description }}
                                    </div>
                                </div>
                            </span>
                        </template>
                        <template v-slot:item.edit="props">
                            <v-icon
                                size="21"
                                @click="openEditEventModal(props.item)"
                                >mdi-pencil</v-icon
                            >
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            circle
                            v-model="page"
                            :length="pageCount"
                        ></v-pagination>
                    </div>
                </div>
            </v-col>
        </v-row>
        <Modal
            :title="'Dodaj zdarzenie'"
            :open="openNewEventModalBoolean"
            :height="'560'"
            v-on:close="closeNewEventModal()"
        >
            <NewEventModalContent slot="AEContent" />
            <NewEventModalButtons
                @closeModal="openNewEventModalBoolean = false"
                :action="'create'"
                slot="buttons"
            />
        </Modal>
        <Modal
            :title="'Edycja zdarzenia'"
            :open="openEditEventModalBoolean"
            :height="'560'"
            v-on:close="closeEditEventModal()"
        >
            <NewEventModalContent slot="AEContent" />
            <NewEventModalButtons
                @closeModal="openEditEventModalBoolean = false"
                :action="'edit'"
                slot="buttons"
            />
        </Modal>
    </v-container>
</template>

<script>
import moment from 'moment'
import NewEventModalContent from './NewEventModalContent.vue'
import NewEventModalButtons from './NewEventModalButtons.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data() {
        return {
            currentClosestEventId: '',
            openNewEventModalBoolean: false,
            openEditEventModalBoolean: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 3,
            perPageValues: [5, 10, 15, 20],
            headers: [
                {
                    text: 'Zdarzenie',
                    align: 'start',
                    width: '1%',
                    value: 'event',
                },

                {
                    text: 'Reprezentant / pracownik',
                    value: 'createdBy',
                    width: '10%',
                },
                { text: 'Opis', value: 'description', width: '25%' },
                {
                    text: 'Planowanie',
                    align: 'start',
                    width: '3%',
                    value: 'statuses',
                },
                {
                    text: '',
                    align: 'start',
                    width: '0.1%',
                    value: 'offerBoolean',
                },
                { text: '', value: 'edit', width: '1%' },
            ],
        }
    },
    components: {
        NewEventModalContent,
        NewEventModalButtons,
    },
    computed: {
        ...mapGetters([
            'getCRMStatuses',
            'getCRMRegistry',
            'getCRMDetails',
            'getCRMEventsData',
        ]),
    },
    methods: {
        ...mapMutations([
            'setOfferStatuses',
            'setOfferRegistry',
            'clearRepresentativesInput',
            'SET_CRM_NEW_EVENT_MODAL',
            'CLEAR_CRM_EVENT_MODAL',
            'SET_CRM_EVENT_DATA',
        ]),
        ...mapActions(['fetchCRMRecord', 'updateOfferStatus']),
        resetTable() {
            this.currentClosestEventId = ''
            this.fetchCRMRecord({ id: this.$route.params.id })
        },
        sortByNextEventDate() {
            // FILTER TO GET ONLY DATA WITH NEXT EVENT DATE
            this.SET_CRM_EVENT_DATA(
                this.getCRMEventsData.filter(e => e.nextEventDate !== null)
            )
            this.SET_CRM_EVENT_DATA(
                this.getCRMEventsData.sort(function (a, b) {
                    return new Date(a.nextEventDate) - new Date(b.nextEventDate)
                })
            )
            if (this.getCRMEventsData.length > 0) {
                return true
            } else {
                return false
            }
        },
        findPageWithEventClosestCurrentDate() {
            const checkIsDataExist = this.sortByNextEventDate()

            if (checkIsDataExist) {
                const currentDate = moment()
                // COPY OF MAIN ARRAY
                let tempEventsArray = structuredClone(this.getCRMEventsData)

                // FIND IN ARRAY THE CLOSEST DATE
                if (tempEventsArray.length > 1) {
                    tempEventsArray.sort(function (a, b) {
                        let distancea = Math.abs(
                            currentDate - moment(a.nextEventDate)
                        )
                        let distanceb = Math.abs(
                            currentDate - moment(b.nextEventDate)
                        )
                        return distancea - distanceb
                    })
                    // FIND EVENT INDEX WITH CLOSEST DATE
                    this.currentClosestEventId = tempEventsArray[0]._id
                    let eventIndexWithClosestDate = this.getCRMEventsData.findIndex(
                        e => e._id === tempEventsArray[0]._id
                    )

                    // ALL AVALIABLE INDEX IN ARRAY
                    const allIndexArray = this.getCRMEventsData.map(
                        (currElement, index) => {
                            return index
                        }
                    )

                    const perChunk = this.itemsPerPage

                    // SPLIT INDEXES INTO ARRAY OF INDEXES
                    const result = allIndexArray.reduce(
                        (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk)

                            if (!resultArray[chunkIndex]) {
                                resultArray[chunkIndex] = []
                            }

                            resultArray[chunkIndex].push(item)

                            return resultArray
                        },
                        []
                    )

                    // FIND ARRAY WITH SELECTED INDEX
                    for (const [index, value] of result.entries()) {
                        if (value.includes(eventIndexWithClosestDate)) {
                            this.page = index + 1
                            break
                        }
                    }
                } else {
                    // IF EXIST ONLY ONE VALUE IN TABLE
                    this.currentClosestEventId = this.getCRMEventsData[0]._id
                    this.page = 1
                }
            }
        },
        convertBooleanToYesOrNo(value) {
            if (value === true) {
                return 'Tak'
            } else {
                return 'Nie'
            }
        },
        checkRepresentativeType(item) {
            if (item?.representativeDecisionType) {
                return item.representativeDecisionType
            }
            return false
        },
        checkNextEventType(item) {
            console.log(item?.nextEventType?.field)
            if (item?.eventType?.field) {
                return item?.nextEventType?.field
            }
        },
        openNewEventModal() {
            this.page = 1
            this.currentClosestEventId = ''
            this.CLEAR_CRM_EVENT_MODAL()
            this.openNewEventModalBoolean = true
        },
        async openEditEventModal(item) {
            await this.fetchCRMRecord({ id: this.getCRMDetails._id })
            this.currentClosestEventId = ''
            this.CLEAR_CRM_EVENT_MODAL()
            this.SET_CRM_NEW_EVENT_MODAL(item)
            this.openEditEventModalBoolean = true
        },
        checkIconType_P(item) {
            if (item.nextEventDate) {
                let nextEventDate = moment(item.nextEventDate)

                if (item?.nextEventTime) {
                    const hours = item.nextEventTime.slice(0, 2)
                    const minutes = item.nextEventTime.slice(3, 5)
                    nextEventDate = nextEventDate
                        .set({
                            hour: hours,
                            minute: minutes,
                        })
                        .format('YYYY/MM/DD HH:mm')
                }
                nextEventDate = moment(nextEventDate, 'YYYY/MM/DD HH:mm')

                const checkIsBeforeCurrentDate =
                    nextEventDate.isAfter(new Date()) ||
                    nextEventDate.isSame(new Date())

                return checkIsBeforeCurrentDate
            } else {
                return false
            }
        },
        closeNewEventModal() {
            this.openNewEventModalBoolean = false
        },
        closeEditEventModal() {
            this.openEditEventModalBoolean = false
        },
        itemRowBackground(item) {
            if (
                this.currentClosestEventId !== '' &&
                item._id === this.currentClosestEventId
            ) {
                return 'style-1'
            }
        },
    },
}
</script>

<style lang="sass">
.left-items-list
    display: grid
    margin-top: 15px
    grid-template-columns: 1fr
    .header
        font-weight: bold
.description-event
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 4
    line-clamp: 4
    -webkit-box-orient: vertical

.column--event
    display: grid
    grid-template-columns: 1fr
    &--secondary
        display: flex
        font-size: 12px
        color: grey
    &--representative
        display: grid
        grid-template-columns: 1fr

.column--representative
    .position
        padding-left: 16px
        font-size: 0.8rem
        color: #445D75
    .email
        display: flex
        .v-icon
            padding-left: 1px
            padding-right: 5px
        span
            font-size: 0.8rem
    .phone
        display: flex
        .v-icon
            padding-left: 1px
            padding-right: 5px
        span
            font-size: 0.8rem
.events-icons-container
    margin-left: 12px


.v-avatar
    cursor: pointer

.column--event--icons
    display: grid
    gap: 5px
    justify-content: left
    .planning_text
        font-size: 12px
        color: grey
    .v-avatar
        font-size: 0.7rem
        margin-left: 3px
        .white--text
            cursor: pointer
            margin-left: -1px
.worker-container
    display: flex
    font-size: 0.8rem
.dot
    margin-left: 3px
    margin-right: 5px
    height: 8px
    width: 8px
    border-radius: 50%
    display: inline-block
.nextEvent-container
    display: flex
    margin-left: 3px
    margin-right: 5px
    margin-top: 2px

.decisionON
    background-color: #19AA8D
    cursor: pointer
.decisionOFF
    background-color: #EB8C8C
    cursor: pointer
.decisionIMPACT
    background-color: #FF9900
    cursor: pointer
.decisionNOINFO
    background-color: #8A8A8A
    cursor: pointer
.event-container
    .v-data-table
        min-height: 380px
        td.text-start
            padding-top: 10px !important
            padding-bottom: 10px !important
            vertical-align: top !important
.events-icons-container
    .D-avatar-icon
        cursor: pointer
        color: white
        margin-left: 4px
        border-radius: 10px
    .P-avatar-icon
        cursor: pointer
        margin-left: 4px
        border-radius: 10px
    .P-text
        font-size: 0.8rem
        color: white
.style-1
    background-color: #e6e3e3
</style>
