<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCRMErrors.stepOne.validation]"
                                editable
                                step="1"
                                >{{ $t('offers:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCRMErrors.stepTwo.validation]"
                                editable
                                step="2"
                                >{{ 'Opisy' }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getCRMErrors.stepThree.validation,
                                ]"
                                editable
                                step="3"
                                >{{ 'Uczestnicy' }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-form
                                    ref="editInformationValidationStepOne"
                                    v-model="
                                        getValidationSteps.editInformationValidationStepOne
                                    "
                                >
                                    <v-row>
                                        <v-col cols="12" md="12" class="pt-0">
                                            <Separator
                                                class="mb-3"
                                                :text="$t('offers:basicInfo')"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6"
                                            ><v-text-field
                                                dense
                                                v-model="getCRMModal.title"
                                                :rules="requiredRules"
                                                @change="
                                                    validateEditInformationValidationStepOne()
                                                "
                                                :label="`${$t(
                                                    'offers:title'
                                                )}*`"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-combobox
                                                :rules="requiredRules"
                                                dense
                                                v-model="getCRMModal.contractor"
                                                :label="`${$t(
                                                    'offers:contractor'
                                                )}*`"
                                                hint="Zacznij pisać..."
                                                @keyup="
                                                    setContacts(
                                                        $event.target.value
                                                    )
                                                "
                                                @input="assignID()"
                                                :hide-no-data="hideContacts"
                                                :items="getContactsInput.items"
                                                item-text="name"
                                                item-value="_id"
                                                return-object
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .contractor
                                                "
                                                @change="
                                                    ifClientOperationLocked()
                                                    validateEditInformationValidationStepOne()
                                                "
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateEditInformationValidationStepOne()
                                                "
                                                v-model="getCRMModal.offerType"
                                                :items="
                                                    getRegistryDictionaries.crmOfferType
                                                        ? getRegistryDictionaries.crmOfferType
                                                        : []
                                                "
                                                dense
                                                :label="$t('offers:offerKind')"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateEditInformationValidationStepOne()
                                                "
                                                v-model="
                                                    getCRMModal.customerType
                                                "
                                                :items="
                                                    getRegistryDictionaries.crmCustomerType
                                                        ? getRegistryDictionaries.crmCustomerType
                                                        : []
                                                "
                                                dense
                                                :label="
                                                    $t('offers:customerKind')
                                                "
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="2">
                                            <v-checkbox
                                                class="checkbox--lead"
                                                dense
                                                v-model="
                                                    getCRMModal.leadBoolean
                                                "
                                                :label="'Lead'"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col offset="4" cols="6">
                                            <v-select
                                                dense
                                                v-model="getCRMModal.prognosis"
                                                :items="
                                                    getRegistryDictionaries.crmEventPrognosis
                                                        ? getRegistryDictionaries.crmEventPrognosis
                                                        : []
                                                "
                                                :label="'Rokowanie'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="6" cols="6">
                                            <v-text-field
                                                dense
                                                v-model="
                                                    getCRMModal.sourceOfAcquisition
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .sourceOfAcquisition
                                                "
                                                :label="'Źródło pozyskania'"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            class="mb-1"
                                            :text="'Opis'"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12"
                                        ><v-text-field
                                            v-model="getCRMModal.forecasting"
                                            dense
                                            :label="`Prognozowanie`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12"
                                        ><v-text-field
                                            v-model="getCRMModal.threats"
                                            dense
                                            :label="`Zagrożenia`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12"
                                        ><v-text-field
                                            v-model="
                                                getCRMModal.currentSupplier
                                            "
                                            dense
                                            :label="`Obecny dostawca`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="
                                                getCRMModal.finalEvaluationOfTheProcess
                                            "
                                            dense
                                            :label="`Ocena końcowa procesu`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="3">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            class="mb-6"
                                            :text="'Uczestnicy'"
                                        />
                                    </v-col>
                                </v-row>
                                <v-form
                                    ref="editInformationValidationStepThree"
                                    v-model="
                                        getValidationSteps.editInformationValidationStepThree
                                    "
                                >
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-flex shrink class="select">
                                            <v-select
                                                :rules="[validateArrayRequired]"
                                                @change="
                                                    validateEditInformationValidationStepThree()
                                                "
                                                class="hidden-data"
                                                :items="possibleCRMParticipants"
                                                multiple
                                                dense
                                                v-model="
                                                    getCRMModal.CRMParticipants
                                                "
                                                :label="`Możliwi uczestnicy`"
                                                item-value="_id"
                                                return-object
                                            >
                                                <template
                                                    v-slot:selection="selection"
                                                >
                                                    <span>{{
                                                        selection.item.name
                                                    }}</span>
                                                    <span class="ml-1">{{
                                                        selection.item.lastname
                                                    }}</span>
                                                    <span
                                                        class="mr-1"
                                                        v-if="
                                                            getCRMModal
                                                                .CRMParticipants
                                                                .length > 1
                                                        "
                                                        >,</span
                                                    >
                                                </template>
                                                <template v-slot:item="item">
                                                    <span>{{
                                                        item.item.name
                                                    }}</span>
                                                    <span class="ml-1">{{
                                                        item.item.lastname
                                                    }}</span>
                                                </template></v-select
                                            >
                                        </v-flex>
                                    </v-col>
                                </v-form>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters([
            'getCRMModal',
            'getCRMErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getCRMModal',
            'getCRMParticipants',
            'getCRMStep',
            'getValidationSteps',
        ]),
    },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            expectationsDate: '',
            possibleCRMParticipants: [],
            requiredRules: [v => !!v || 'Pole jest wymagane'],
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
            'checkIsClientOperationLocked',
            'findUsersWithAccessToCRM',
            'setCRMValidation',
        ]),

        validateArrayRequired(array) {
            if (array.length > 0) {
                return true
            } else {
                return 'Wymagany jest conajmniej jeden uczestnik'
            }
        },

        ...mapMutations(['setContactsInput', 'setRepresentativesInput']),
        validateEditInformationValidationStepOne() {
            this.setCRMValidation({
                validationName: 'editInformationValidationStepOne',
                value: this.$refs.editInformationValidationStepOne.validate(),
            })
        },
        validateEditInformationValidationStepThree() {
            this.setCRMValidation({
                validationName: 'editInformationValidationStepThree',
                value: this.$refs.editInformationValidationStepThree.validate(),
            })
        },
        async fetchPossibleCRMParticipants() {
            this.possibleCRMParticipants = await this.findUsersWithAccessToCRM()
        },
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },

        setRepresentatives(search) {
            this.setRepresentativesInput({ search })
            this.fetchRepresentativesInput({
                id: this.getLeasingModal.contact._id,
            })
        },
        assignID() {
            if (
                this.getCRMModal.contractor &&
                this.getCRMModal.contractor._id
            ) {
                this.getCRMModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getCRMModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getCRMModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        async ifClientOperationLocked() {
            const clientId = this.$get(this.getCRMModal, 'contractor', '')?._id

            if (clientId) {
                const payload = {
                    id: clientId,
                }
                const checkClientOperationLocked = await this.checkIsClientOperationLocked(
                    payload
                )

                if (checkClientOperationLocked.data) {
                    this.getCRMErrors.stepOne.fields.contractor =
                        'Klient na liście zablokowanych'

                    this.$forceUpdate()
                } else {
                    this.getCRMErrors.stepOne.fields.contractor = ''

                    this.$forceUpdate()
                }
            }
        },
    },
    async mounted() {
        await this.fetchPossibleCRMParticipants()
    },
}
</script>
<style lang="sass" scoped>
.checkbox--lead
    font-weight: bold
.select 
    min-width: 100px

</style>
