<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="12"
                ><div class="raport-container pa-2 ml-1 mt-2">
                    <div>
                        <span>
                            <v-tooltip top>
                                <template
                                    v-slot:activator="{
                                        on,
                                    }"
                                >
                                    <v-icon
                                        :disabled="
                                            getCRMDetails.reportPage.lockValue
                                        "
                                        @click="openNewReportItemModal"
                                        class="new-event-icon"
                                        size="16"
                                        v-on="on"
                                        color="secondary"
                                        >mdi-plus-box</v-icon
                                    >
                                </template>
                                <span>Dodaj element</span>
                            </v-tooltip>
                        </span>
                    </div>
                </div>
                <div class="reportTable">
                    <v-data-table
                        :height="'450px'"
                        :headers="headers"
                        :page.sync="page"
                        fixed-header
                        :items-per-page="itemsPerPage"
                        :items="getCRMReportItems"
                        hide-default-footer
                        @page-count="pageCount = $event"
                    >
                        <template v-slot:item.model="props">
                            {{ $get(props.item, 'model.manufacturer', '') }}
                            {{ $get(props.item, 'model.model', '') }}
                        </template>
                        <template v-slot:item.worker="props">
                            <div>
                                {{ $get(props.item.worker, 'name', '') }}
                                {{ $get(props.item.worker, 'lastname', '') }}
                            </div>
                        </template>
                        <template v-slot:item.subscriptionType="props">
                            <div>
                                {{
                                    $get(
                                        props.item,
                                        'subscriptionType.field',
                                        ''
                                    )
                                }}
                            </div>
                        </template>
                        <template v-slot:item.createdAt="props">
                            {{
                                $moment(props.item.createdAt).format(
                                    'DD/MM/YYYY'
                                )
                            }}
                        </template>
                        <template v-slot:item.deviceState="props">
                            {{
                                $get(props.item, 'deviceState.field', '')
                            }}</template
                        >
                        <template v-slot:item.isAnnex="props">
                            {{ props.item.isAnnex }}
                        </template>
                        <template v-slot:item.durationOfTheContract="props">
                            {{
                                $get(
                                    props.item,
                                    'durationOfTheContract.field',
                                    ''
                                )
                            }}
                        </template>
                        <template v-slot:item.comments="props">
                            <v-tooltip max-width="600" left>
                                <template
                                    v-slot:activator="{
                                        on,
                                    }"
                                >
                                    <div v-on="on" class="comments-table-text">
                                        {{ props.item.comments }}
                                    </div>
                                </template>
                                <span>{{ props.item.comments }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.edit="props">
                            <div class="modify-icons">
                                <v-icon
                                    :disabled="
                                        getCRMDetails.reportPage.lockValue
                                    "
                                    size="16"
                                    :color="'green'"
                                    @click="openEditReportItemModal(props.item)"
                                    >mdi-pencil</v-icon
                                ><v-icon
                                    :disabled="
                                        getCRMDetails.reportPage.lockValue
                                    "
                                    size="16"
                                    :color="'red'"
                                    @click="openConfirmDeleteModal()"
                                    >mdi-delete</v-icon
                                >
                                <ConfirmModal
                                    v-if="confirmDeleteBoolean"
                                    :width="'720'"
                                    :openConfirm="confirmDeleteBoolean"
                                    @close-confirm="closeConfirmDeleteModal"
                                >
                                    <div slot="main">
                                        <DeleteConfirm />
                                    </div>
                                    <div slot="buttons">
                                        <div class="d-flex justify-center mt-5">
                                            <v-btn
                                                width="104"
                                                height="53"
                                                color="#FF9900"
                                                @click="
                                                    closeConfirmDeleteModal()
                                                "
                                                class="white--text mx-2"
                                                >NIE</v-btn
                                            >
                                            <v-btn
                                                width="104"
                                                height="53"
                                                color="#19AA8D"
                                                class="white--text mx-2"
                                                @click="
                                                    deleteReportItem(props.item)
                                                "
                                                >TAK</v-btn
                                            >
                                        </div>
                                    </div>
                                </ConfirmModal>
                            </div>
                        </template></v-data-table
                    >
                    <div class="text-center pt-2">
                        <v-pagination
                            circle
                            v-model="page"
                            :length="pageCount"
                        ></v-pagination>
                    </div>
                </div>
            </v-col>
            <v-col cols="3" class="mt-4"
                ><div class="lock-container">
                    <div
                        v-if="!getCRMDetails.reportPage.lockValue"
                        class="lock-icon"
                    >
                        <v-icon
                            color="#19AA8D"
                            @click="openCloseOfSaleModal"
                            small
                            >mdi-lock</v-icon
                        >
                    </div>
                    <div class="locks">
                        <div
                            v-if="getCRMDetails.reportPage.lockValue"
                            class="lock-icon"
                        >
                            <v-icon color="#B7B7B7" small>mdi-lock</v-icon>
                        </div>
                        <div
                            v-if="
                                getCRMDetails.reportPage.lockValue &&
                                getProfileDetails.isAdmin
                            "
                            class="lock-icon specialAccess"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        v-on="on"
                                        color="#19AA8D"
                                        @click="unlockTheLock"
                                        small
                                        >mdi-lock-open-variant</v-icon
                                    >
                                </template>
                                <span>{{ 'Odblokuj dostęp' }}</span>
                            </v-tooltip>
                        </div>
                    </div>

                    <hr class="divider-line" />
                    <div
                        v-if="!getCRMDetails.reportPage.lockValue"
                        class="lock-text-container"
                    >
                        <div class="lock-text">
                            Twoja sprzedaż nie została zamknięta
                        </div>
                        <div class="lock-text">
                            Kliknij by zamknąć do raportowania.
                        </div>
                    </div>
                    <div
                        v-if="getCRMDetails.reportPage.lockValue"
                        class="lock-text-container"
                    >
                        <div class="lock-text">
                            Twoja sprzedaż została zamknięta
                            {{
                                $moment(
                                    getCRMDetails.reportPage.lockDate
                                ).format('DD/MM/YYYY')
                            }}
                        </div>
                    </div>
                </div></v-col
            >
            <v-col cols="9" class="mt-4">
                <v-textarea
                    v-model="getCRMDetails.reportPage.comments"
                    :label="`Uwagi`"
                    rows="1"
                    readonly
                >
                </v-textarea>
            </v-col>
        </v-row>

        <div class="d-flex justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        small
                        @click="editPage()"
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ 'Edycja zakładki' }}</span>
            </v-tooltip>
        </div>
        <Modal
            :title="'Dodawanie'"
            :open="openNewReportItemModalBoolean"
            :height="'600'"
            v-on:close="closeModal()"
        >
            <AEContent :action="'Dodawanie'" slot="AEContent" />
            <NewReportItemModalButtons
                @closeModal="openNewReportItemModalBoolean = false"
                :action="'create'"
                slot="buttons"
            />
        </Modal>
        <Modal
            :title="'Edycja'"
            :open="editReportItemModalBoolean"
            :height="'600'"
            v-on:close="closeEditReportItemModal()"
        >
            <AEContent :action="'Edycja'" slot="AEContent" />
            <NewReportItemModalButtons
                @closeModal="editReportItemModalBoolean = false"
                :action="'edit'"
                slot="buttons"
            />
        </Modal>
        <ConfirmModal
            :width="'720'"
            :openConfirm="closeOfSaleModalBoolean"
            @close-confirm="closeCloseOfSaleModal"
        >
            <div slot="main">
                <ConfirmContentCloseSale />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center mt-5">
                    <v-btn
                        width="104"
                        height="53"
                        color="#FF9900"
                        @click="closeCloseOfSaleModal()"
                        class="white--text mx-2"
                        >NIE</v-btn
                    >
                    <v-btn
                        width="104"
                        height="53"
                        color="#19AA8D"
                        class="white--text mx-2"
                        @click="closeTheLock()"
                        >TAK</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>

        <Modal
            :title="'Edycja'"
            :open="open"
            :height="'520'"
            v-on:close="closeMainPageEditModal()"
        >
            <MainContentEditModal slot="AEContent" />
            <Buttons
                :type="'CRM_REPORT'"
                @closeModal="open = false"
                :action="'edit'"
                slot="buttons"
            />
        </Modal>
    </v-container>
</template>

<script>
import AEContent from '../Details/CRMReportItemModal.vue'
import MainContentEditModal from '../Details/CRMReportMainPageModal.vue'
import NewReportItemModalButtons from '../Details/NewReportItemModalButtons.vue'
import Buttons from '../Details/DetailsButtonsSaveEdit.vue'
import ConfirmContentCloseSale from '../Details/ConfirmContentCloseSale.vue'
import DeleteConfirm from '../Details/DeleteConfirm.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {},
    components: {
        AEContent,
        NewReportItemModalButtons,
        Buttons,
        MainContentEditModal,
        ConfirmContentCloseSale,
        DeleteConfirm,
    },
    data() {
        return {
            confirmDeleteBoolean: false,
            editReportItemModalBoolean: false,
            openEditItemReportModalBoolean: false,
            closeOfSaleModalBoolean: false,
            open: false,
            openNewReportItemModalBoolean: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 7,
            headers: [
                {
                    text: 'Data utworzenia',
                    align: 'start',
                    width: '4%',
                    value: 'createdAt',
                },
                {
                    text: 'Pracownik',
                    align: 'start',
                    width: 'auto',
                    value: 'worker',
                },
                {
                    text: 'Stan urządzenia',
                    align: 'start',
                    width: 'auto',
                    value: 'deviceState',
                },
                {
                    text: 'Model urządzenia',
                    align: 'start',
                    width: '10%',
                    value: 'model',
                },
                {
                    text: 'Rodzaj',
                    value: 'subscriptionType',
                    width: '8%',
                },
                {
                    text: 'FTTR',
                    value: 'fttrValue',
                    width: '5.5%',
                },

                {
                    text: 'Numer umowy/FV',
                    value: 'contractNumber',
                    width: '10%',
                },
                {
                    text: 'Czas trwania umowy',
                    value: 'durationOfTheContract',
                    width: '12%',
                },
                {
                    text: 'Aneks',
                    value: 'isAnnex',
                    width: '5.5%',
                },
                {
                    text: 'Aneksuje umowę',
                    value: 'blankingContract',
                    width: '10%',
                },
                {
                    text: 'Uwagi',
                    value: 'comments',
                    width: '10%',
                },
                { text: '', value: 'edit', width: '1%' },
            ],
        }
    },

    computed: {
        ...mapGetters([
            'getCRMDetails',
            'getCRMReportItems',
            'getProfileDetails',
        ]),
    },
    methods: {
        ...mapActions(['editCRM', 'fetchCRMRecord', 'deleteReportItemFunc']),
        ...mapMutations([
            'setCRMModal',
            'SET_LOCK_VALUE',
            'SET_UNLOCK_VALUE',
            'SET_CRM_REPORT_MODAL',
            'CLEAR_CRM_REPORT_NEW_ITEM_MODAL',
            'CLEAR_CRM_MODAL',
        ]),

        openConfirmDeleteModal() {
            this.confirmDeleteBoolean = true
        },
        closeConfirmDeleteModal() {
            this.confirmDeleteBoolean = false
        },

        closeEditReportItemModal() {
            this.editReportItemModalBoolean = false
        },
        async deleteReportItem(item) {
            const result = await this.deleteReportItemFunc({
                routeId: this.$route.params.id,
                itemId: item._id,
            })
            if (result) {
                this.closeConfirmDeleteModal()
                return await this.fetchCRMRecord({ id: this.$route.params.id })
            }
        },
        async closeTheLock() {
            this.setCRMModal()
            this.SET_LOCK_VALUE()
            await this.editCRM({ id: this.$route.params.id })
            await this.fetchCRMRecord({ id: this.$route.params.id })
            this.closeCloseOfSaleModal()
        },
        async unlockTheLock() {
            this.setCRMModal()
            this.SET_UNLOCK_VALUE()
            await this.editCRM({ id: this.$route.params.id })
            await this.fetchCRMRecord({ id: this.$route.params.id })
        },
        openEditReportItemModal(item) {
            this.CLEAR_CRM_MODAL()
            this.SET_CRM_REPORT_MODAL(item)
            this.editReportItemModalBoolean = true
        },
        openNewReportItemModal() {
            this.CLEAR_CRM_MODAL()
            this.CLEAR_CRM_REPORT_NEW_ITEM_MODAL()
            this.openNewReportItemModalBoolean = true
        },
        openCloseOfSaleModal() {
            this.closeOfSaleModalBoolean = true
        },
        closeCloseOfSaleModal() {
            this.closeOfSaleModalBoolean = false
        },

        async editPage() {
            this.open = true
            this.CLEAR_CRM_MODAL()
            await this.fetchCRMRecord({ id: this.getCRMDetails._id })
            this.setCRMModal()
        },
        closeModal() {
            this.openNewReportItemModalBoolean = false
        },
        closeMainPageEditModal() {
            this.open = false
        },
    },
}
</script>
<style lang="scss" scoped>
.reportTable .v-data-table::v-deep th {
    font-size: 11px !important;
}
.reportTable .v-data-table::v-deep td {
    font-size: 11px !important;
}
.reportTable .v-data-table {
    overflow-y: auto;
}
.reportTable .v-data-table__wrapper::-webkit-scrollbar {
    width: 24px;
    height: 8px;
    background-color: #143861;
}
.reportTable {
    .modify-icons {
        display: flex;
    }
    .comments-table-text {
        cursor: pointer;
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.lock-container {
    display: grid;
    justify-items: center;
    background: #fafddc;
    height: 75px;
    .locks {
        display: flex;
        .specialAccess {
            margin-left: 20px;
        }
    }

    .divider-line {
        border: 0;
        clear: both;
        display: block;
        width: 40%;
        border: 1px solid #b1b1b1;
        height: 1px;
    }
    .lock-text-container {
        display: grid;
        justify-items: center;
        .lock-text {
            justify-items: center;
            font-size: 12px;
        }
    }
}
</style>
