<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCRMErrors.stepOne.validation]"
                                editable
                                step="1"
                                >{{ 'Zdarzenie' }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-form
                                    ref="crmEventValidationStepOne"
                                    v-model="
                                        getValidationSteps.crmEventValidationStepOne
                                    "
                                >
                                    <v-row>
                                        <v-col cols="12" md="12" class="pt-0">
                                            <Separator
                                                class="mb-3"
                                                :text="'Zdarzenie'"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateCrmEventValidationStepOne()
                                                "
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .eventType
                                                "
                                                dense
                                                :items="
                                                    getRegistryDictionaries.crmEventType
                                                        ? getRegistryDictionaries.crmEventType
                                                        : []
                                                "
                                                :label="'Zdarzenie'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="8">
                                            <!-- USUNALEM :item-text="text" PROPS -->
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateCrmEventValidationStepOne()
                                                "
                                                dense
                                                :label="`${$t(
                                                    'offers:representative'
                                                )}`"
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .representative
                                                "
                                                return-object
                                                append-outer-icon="mdi-account-plus"
                                                item-value="_id"
                                                :items="
                                                    findAvailableRepresentatives
                                                "
                                                ><template
                                                    slot="selection"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }}
                                                    {{ data.item.lastname }}
                                                </template>
                                                <template
                                                    slot="item"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }}
                                                    {{ data.item.lastname }}
                                                </template>
                                                <template slot="append-outer">
                                                    <v-menu
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :close-on-click="false"
                                                        @input="
                                                            v =>
                                                                v ||
                                                                clearMiniModal()
                                                        "
                                                        max-width="300"
                                                        min-width="300"
                                                        origin="center"
                                                        v-model="showMenu"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on: menu,
                                                            }"
                                                        >
                                                            <v-tooltip top>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on: tooltip,
                                                                    }"
                                                                >
                                                                    <v-btn
                                                                        small
                                                                        icon
                                                                    >
                                                                        <v-icon
                                                                            v-on="{
                                                                                ...menu,
                                                                                ...tooltip,
                                                                            }"
                                                                            @click="
                                                                                showMenu = true
                                                                            "
                                                                            >mdi-plus</v-icon
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        'offers:addRepresentative'
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-card>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <h3>
                                                                            {{
                                                                                `${$t(
                                                                                    'offers:additionRepresentative'
                                                                                )}`
                                                                            }}
                                                                        </h3>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.name
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeName'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.lastname
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeLastname'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.position
                                                                            "
                                                                            :label="`${$t(
                                                                                'Stanowisko'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.email
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativeEmail'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>

                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.phoneNumber
                                                                            "
                                                                            :label="`${$t(
                                                                                'offers:representativePhoneNumber'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        md="12"
                                                                        class="py-0 my-0 d-flex justify-end"
                                                                    >
                                                                        <v-btn
                                                                            class="buttons buttons--cancel mr-2"
                                                                            @click="
                                                                                clearMiniModal()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'cancel'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                        <v-btn
                                                                            class="buttons buttons--add"
                                                                            :disabled="
                                                                                disabled
                                                                            "
                                                                            @click="
                                                                                additionRepresentative()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'add'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="4" cols="4">
                                            <v-select
                                                :rules="requiredRules"
                                                @change="
                                                    validateCrmEventValidationStepOne()
                                                "
                                                dense
                                                :label="`Decyzyjność reprezentanta`"
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .representativeDecisionType
                                                "
                                                return-object
                                                :items="[
                                                    'Decyzyjny',
                                                    'Niedecyzyjny',
                                                    'Wpływ na decyzję',
                                                    'Brak informacji',
                                                ]"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12"
                                            ><v-textarea
                                                dense
                                                rows="1"
                                                auto-grow
                                                no-resize
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .description
                                                "
                                                :error-messages="
                                                    getCRMErrors.stepOne.fields
                                                        .title
                                                "
                                                :label="`Opis zdarzenia`"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col offset="6" cols="4">
                                            <v-menu
                                                dense
                                                v-model="expectationsDate"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getCRMNewEventModal
                                                                .crmEvent
                                                                .nextEventDate
                                                        "
                                                        clearable
                                                        :label="`Kolejne zdarzenie - data`"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    v-model="
                                                        getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventDate
                                                    "
                                                    @input="
                                                        expectationsDate = false
                                                    "
                                                    :min="
                                                        $moment().format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    "
                                                ></v-date-picker> </v-menu
                                        ></v-col>
                                        <v-col cols="2">
                                            <v-menu
                                                ref="menu2"
                                                v-model="expectedTime"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="
                                                    getCRMNewEventModal.crmEvent
                                                        .nextEventTime
                                                "
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getCRMNewEventModal
                                                                .crmEvent
                                                                .nextEventTime
                                                        "
                                                        clearable
                                                        :label="`${$t(
                                                            'service:time'
                                                        )}`"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="expectedTime"
                                                    format="24hr"
                                                    v-model="
                                                        getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventTime
                                                    "
                                                    :min="
                                                        getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventDate ==
                                                        $moment().format(
                                                            'YYYY-MM-DD'
                                                        )
                                                            ? $moment().format(
                                                                  `HH:mm`
                                                              )
                                                            : null
                                                    "
                                                    full-width
                                                    @click:minute="
                                                        $refs.menu2.save(
                                                            getCRMNewEventModal
                                                                .crmEvent
                                                                .nextEventTime
                                                        )
                                                    "
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="2">
                                            <v-checkbox
                                                class="checkbox--lead"
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .offerBoolean
                                                "
                                                :label="'Oferta'"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col offset="1" cols="4">
                                            <v-select
                                                :disabled="
                                                    !getCRMNewEventModal
                                                        .crmEvent.offerBoolean
                                                "
                                                :label="`Status oferty`"
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .offerStatus
                                                "
                                                return-value
                                                :items="itemsInOfferStatus"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col offset="1" cols="4">
                                            <v-select
                                                v-model="
                                                    getCRMNewEventModal.crmEvent
                                                        .nextEventType
                                                "
                                                :items="
                                                    getRegistryDictionaries.crmEventType
                                                        ? getRegistryDictionaries.crmEventType
                                                        : []
                                                "
                                                :label="'Kolejne zdarzenie - rodzaj'"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'getCRMDetails',
            'getCRMErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getCRMNewEventModal',
            'getCRMStep',
            'getValidationSteps',
        ]),
        itemsInOfferStatus() {
            if (this.checkIsRecordContainsWin) {
                return [
                    { text: 'Wygrana', value: 'Wygrana', disabled: true },
                    { text: 'Przegrana', value: 'Przegrana', disabled: false },
                    { text: 'Odrzucona', value: 'Odrzucona', disabled: false },
                    { text: 'Anulowana', value: 'Anulowana', disabled: false },
                ]
            }
            return [
                { text: 'Wygrana', value: 'Wygrana', disabled: false },
                { text: 'Przegrana', value: 'Przegrana', disabled: false },
                { text: 'Odrzucona', value: 'Odrzucona', disabled: false },
                { text: 'Anulowana', value: 'Anulowana', disabled: false },
            ]
        },
        checkIsRecordContainsWin() {
            if (this.getCRMDetails?.recordContainsWin) {
                return true
            }
            return false
        },
        text: item => item.name + item.lastname,
        findAvailableRepresentatives() {
            if (this.getCRMDetails?.contractor?.representatives.length > 0) {
                return this.getCRMDetails.contractor.representatives
            }
            return []
        },
    },
    data() {
        return {
            // EXTRAS VARIABLES
            showMenu: false,
            disabled: false,
            expectationsDate: '',
            expectedTime: '',
            // COMPONENT DATA
            requiredRules: [v => !!v || 'Pole jest wymagane'],
        }
    },
    methods: {
        ...mapActions([
            'setCRMValidation',
            'createRepresentative',
            'fetchCRMRecord',
        ]),
        validateCrmEventValidationStepOne() {
            this.setCRMValidation({
                validationName: 'crmEventValidationStepOne',
                value: this.$refs.crmEventValidationStepOne.validate(),
            })
        },

        async additionRepresentative() {
            this.disabled = true

            let success = await this.createRepresentative({
                id: this.getCRMDetails.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.fetchCRMRecord({ id: this.getCRMDetails._id })
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
    },
}
</script>

<style></style>
