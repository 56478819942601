var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getCRMStep.step),callback:function ($$v) {_vm.$set(_vm.getCRMStep, "step", $$v)},expression:"getCRMStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[() => _vm.getCRMErrors.stepOne.validation],"editable":"","step":"1"}},[_vm._v(_vm._s('Zdarzenie'))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-form',{ref:"crmEventValidationStepOne",model:{value:(
                                    _vm.getValidationSteps.crmEventValidationStepOne
                                ),callback:function ($$v) {_vm.$set(_vm.getValidationSteps, "crmEventValidationStepOne", $$v)},expression:"\n                                    getValidationSteps.crmEventValidationStepOne\n                                "}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-3",attrs:{"text":'Zdarzenie'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"rules":_vm.requiredRules,"dense":"","items":_vm.getRegistryDictionaries.crmEventType
                                                    ? _vm.getRegistryDictionaries.crmEventType
                                                    : [],"label":'Zdarzenie',"return-object":"","item-value":"field"},on:{"change":function($event){return _vm.validateCrmEventValidationStepOne()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .eventType
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "eventType", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .eventType\n                                            "}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"rules":_vm.requiredRules,"dense":"","label":`${_vm.$t(
                                                'offers:representative'
                                            )}`,"return-object":"","append-outer-icon":"mdi-account-plus","item-value":"_id","items":_vm.findAvailableRepresentatives},on:{"change":function($event){return _vm.validateCrmEventValidationStepOne()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}}]),model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .representative
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "representative", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .representative\n                                            "}},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"300","min-width":"300","origin":"center"},on:{"input":v =>
                                                            v ||
                                                            _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                            on: menu,
                                                        }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                    on: tooltip,
                                                                }){return [_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){_vm.showMenu = true}}},{
                                                                            ...menu,
                                                                            ...tooltip,
                                                                        }),[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'offers:addRepresentative' )))])])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(`${_vm.$t( 'offers:additionRepresentative' )}`)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                            'offers:representativeName'
                                                                        )}`},model:{value:(
                                                                            _vm.getRepresentativeModal.name
                                                                        ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "name", $$v)},expression:"\n                                                                            getRepresentativeModal.name\n                                                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                            'offers:representativeLastname'
                                                                        )}`},model:{value:(
                                                                            _vm.getRepresentativeModal.lastname
                                                                        ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "lastname", $$v)},expression:"\n                                                                            getRepresentativeModal.lastname\n                                                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                            'Stanowisko'
                                                                        )}`},model:{value:(
                                                                            _vm.getRepresentativeModal.position
                                                                        ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "position", $$v)},expression:"\n                                                                            getRepresentativeModal.position\n                                                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                            'offers:representativeEmail'
                                                                        )}`},model:{value:(
                                                                            _vm.getRepresentativeModal.email
                                                                        ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "email", $$v)},expression:"\n                                                                            getRepresentativeModal.email\n                                                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                            'offers:representativePhoneNumber'
                                                                        )}`},model:{value:(
                                                                            _vm.getRepresentativeModal.phoneNumber
                                                                        ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "phoneNumber", $$v)},expression:"\n                                                                            getRepresentativeModal.phoneNumber\n                                                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"buttons buttons--cancel mr-2",on:{"click":function($event){return _vm.clearMiniModal()}}},[_vm._v(_vm._s(_vm.$t( 'cancel' )))]),_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.additionRepresentative()}}},[_vm._v(_vm._s(_vm.$t( 'add' )))])],1)],1)],1)],1)],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset":"4","cols":"4"}},[_c('v-select',{attrs:{"rules":_vm.requiredRules,"dense":"","label":`Decyzyjność reprezentanta`,"return-object":"","items":[
                                                'Decyzyjny',
                                                'Niedecyzyjny',
                                                'Wpływ na decyzję',
                                                'Brak informacji',
                                            ]},on:{"change":function($event){return _vm.validateCrmEventValidationStepOne()}},model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .representativeDecisionType
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "representativeDecisionType", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .representativeDecisionType\n                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"1","auto-grow":"","no-resize":"","error-messages":_vm.getCRMErrors.stepOne.fields
                                                    .title,"label":`Opis zdarzenia`},model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .description
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "description", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .description\n                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset":"6","cols":"4"}},[_c('v-menu',{attrs:{"dense":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                    on,
                                                    attrs,
                                                }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`Kolejne zdarzenie - data`,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(
                                                        _vm.getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal
                                                            .crmEvent
                                                            , "nextEventDate", $$v)},expression:"\n                                                        getCRMNewEventModal\n                                                            .crmEvent\n                                                            .nextEventDate\n                                                    "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.expectationsDate),callback:function ($$v) {_vm.expectationsDate=$$v},expression:"expectationsDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":_vm.$moment().format(
                                                        'YYYY-MM-DD'
                                                    )},on:{"input":function($event){_vm.expectationsDate = false}},model:{value:(
                                                    _vm.getCRMNewEventModal
                                                        .crmEvent
                                                        .nextEventDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal
                                                        .crmEvent
                                                        , "nextEventDate", $$v)},expression:"\n                                                    getCRMNewEventModal\n                                                        .crmEvent\n                                                        .nextEventDate\n                                                "}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.getCRMNewEventModal.crmEvent
                                                    .nextEventTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "nextEventTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "nextEventTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                    on,
                                                    attrs,
                                                }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`${_vm.$t(
                                                        'service:time'
                                                    )}`,"prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(
                                                        _vm.getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventTime
                                                    ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal
                                                            .crmEvent
                                                            , "nextEventTime", $$v)},expression:"\n                                                        getCRMNewEventModal\n                                                            .crmEvent\n                                                            .nextEventTime\n                                                    "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.expectedTime),callback:function ($$v) {_vm.expectedTime=$$v},expression:"expectedTime"}},[(_vm.expectedTime)?_c('v-time-picker',{attrs:{"format":"24hr","min":_vm.getCRMNewEventModal
                                                        .crmEvent
                                                        .nextEventDate ==
                                                    _vm.$moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                        ? _vm.$moment().format(
                                                              `HH:mm`
                                                          )
                                                        : null,"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu2.save(
                                                        _vm.getCRMNewEventModal
                                                            .crmEvent
                                                            .nextEventTime
                                                    )}},model:{value:(
                                                    _vm.getCRMNewEventModal
                                                        .crmEvent
                                                        .nextEventTime
                                                ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal
                                                        .crmEvent
                                                        , "nextEventTime", $$v)},expression:"\n                                                    getCRMNewEventModal\n                                                        .crmEvent\n                                                        .nextEventTime\n                                                "}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"checkbox--lead",attrs:{"label":'Oferta'},model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .offerBoolean
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "offerBoolean", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .offerBoolean\n                                            "}})],1),_c('v-col',{attrs:{"offset":"1","cols":"4"}},[_c('v-select',{attrs:{"disabled":!_vm.getCRMNewEventModal
                                                    .crmEvent.offerBoolean,"label":`Status oferty`,"return-value":"","items":_vm.itemsInOfferStatus},model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .offerStatus
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "offerStatus", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .offerStatus\n                                            "}})],1),_c('v-col',{attrs:{"offset":"1","cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.crmEventType
                                                    ? _vm.getRegistryDictionaries.crmEventType
                                                    : [],"label":'Kolejne zdarzenie - rodzaj',"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                _vm.getCRMNewEventModal.crmEvent
                                                    .nextEventType
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMNewEventModal.crmEvent
                                                    , "nextEventType", $$v)},expression:"\n                                                getCRMNewEventModal.crmEvent\n                                                    .nextEventType\n                                            "}})],1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }