<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ getCRMDetails.contractor.name }}
                        </p>
                        <div class="left-items-list">
                            <span class="header">{{ `Adres` }}:</span>
                            <span
                                >{{
                                    $get(getCRMDetails, 'contractor.city', '')
                                }},
                                {{
                                    $get(
                                        getCRMDetails,
                                        'contractor.postCode',
                                        ''
                                    )
                                }}</span
                            >
                            <span
                                >ul.
                                {{
                                    $get(getCRMDetails, 'contractor.street', '')
                                }}
                                {{
                                    $get(
                                        getCRMDetails,
                                        'contractor.houseNumber',
                                        ''
                                    )
                                }}<span
                                    v-if="
                                        $get(
                                            getCRMDetails,
                                            'contractor.apartmentNumber',
                                            ''
                                        )
                                    "
                                    ><span
                                        >/{{
                                            $get(
                                                getCRMDetails,
                                                'contractor.apartmentNumber',
                                                ''
                                            )
                                        }}</span
                                    >
                                </span>
                            </span>
                        </div>
                        <!-- <div class="left-items-list">
                            <span>{{ `Pochodzenie klienta` }}</span>
                            <span>{{
                                $get(getCRMDetails, 'customerType.field', '')
                            }}</span>
                        </div> -->
                        <div class="left-items-list">
                            <span class="header">{{ `Tytuł:` }}</span>
                            <span> {{ $get(getCRMDetails, 'title', '') }}</span>
                        </div>
                        <div class="left-items-list mb-5">
                            <span class="header">{{ `Lead:` }}</span>
                            <span>{{
                                convertBooleanToYesOrNo(
                                    $get(getCRMDetails, 'leadBoolean', '')
                                )
                            }}</span>
                        </div>

                        <div class="participants-container">
                            <span class="header">{{ `Uczestnicy:` }}</span>

                            <div style="overflow-y: auto; height: 120px;">
                                <div
                                    v-for="item in getCRMDetails.CRMParticipants"
                                    v-bind:key="item.id"
                                >
                                    <div class="participants-inner">
                                        <span>{{ item.name }}</span>
                                        <span>{{ item.lastname }}</span
                                        ><span
                                            v-if="
                                                checkParticipantIsClientGuardian(
                                                    item._id
                                                )
                                            "
                                            style="color: #445d75;"
                                            >- opiekun klienta</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" class="px-10 mt-5">
                <v-row>
                    <v-col
                        ><v-text-field
                            dense
                            readonly
                            :value="$get(getCRMDetails, 'offerType.field', '')"
                            :label="'Przedmiot'"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            dense
                            :value="$get(getCRMDetails, 'prognosis.field', '')"
                            :label="'Rokowanie'"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        ><v-text-field
                            readonly
                            dense
                            :value="$get(getCRMDetails, 'forecasting', '')"
                            :label="'Prognozowanie'"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        ><v-text-field
                            readonly
                            dense
                            :value="$get(getCRMDetails, 'threats', '')"
                            :label="'Zagrożenia'"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        ><v-text-field
                            readonly
                            dense
                            :value="$get(getCRMDetails, 'currentSupplier', '')"
                            :label="'Obecny dostawca'"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            dense
                            class="mt-10"
                            :value="
                                $get(
                                    getCRMDetails,
                                    'finalEvaluationOfTheProcess',
                                    ''
                                )
                            "
                            :label="'Ocena końcowa procesu'"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="3" class="pl-5 mt-5">
                <v-row>
                    <v-col offset="1" cols="10"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                $get(getCRMDetails, 'customerType.field', '')
                            "
                            :label="'Pochodzenie kontrahenta'"
                        ></v-text-field>
                    </v-col>
                    <v-col offset="1" cols="10"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                $get(getCRMDetails, 'sourceOfAcquisition', '')
                            "
                            :label="'Źródło pozyskania'"
                        ></v-text-field>
                    </v-col>
                    <v-col offset="1" cols="10"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                $get(getCRMDetails, 'evaluation.summary', '')
                            "
                            :label="'Ocena potencjału'"
                        ></v-text-field>
                    </v-col>
                    <v-col offset="1" cols="9"
                        ><v-text-field
                            readonly
                            dense
                            :value="
                                presentPreviousProcessWithTheClientDate(
                                    getCRMDetails.previousProcessWithTheClient
                                )
                            "
                            :label="'Poprzedni proces z klientem'"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="
                            presentPreviousProcessWithTheClientDate(
                                getCRMDetails.previousProcessWithTheClient
                            ) !== ''
                        "
                        cols="1"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="openAskAccessModal()"
                                >
                                    mdi-account-edit
                                </v-icon>
                            </template>
                            <span>Poproś o dostęp</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <ConfirmModal
            :width="'700px'"
            :openConfirm="openAskAccessModalBoolean"
            v-on:close-confirm="closeAskAccessModal"
        >
            <div slot="main">
                <AskAcessModalContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="#FF9900"
                        width="100"
                        height="40"
                        @click="closeAskAccessModal()"
                        class="white--text mx-2"
                        >{{ $t('global:no') }}</v-btn
                    >
                    <v-btn
                        width="100"
                        height="40"
                        color="secondary"
                        class="white--text mx-2"
                        @click="
                            confirmAccess(
                                getCRMDetails.previousProcessWithTheClient
                            )
                        "
                        >{{ $t('global:yes') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </v-container>
</template>
<script>
import AskAcessModalContent from './AskAcessModalContent.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        AskAcessModalContent,
    },
    computed: {
        ...mapGetters(['getCRMDetails', 'getCRMRegistry']),
    },
    data() {
        return {
            openAskAccessModalBoolean: false,
        }
    },
    methods: {
        ...mapActions(['createCRMAccessAlert']),
        checkParticipantIsClientGuardian(participantId) {
            const clientGuardianId = this.getCRMDetails?.contractor
                ?.clientGuardian

            if (!clientGuardianId) {
                return false
            }
            if (clientGuardianId === participantId) {
                return true
            }
            return false
        },
        async confirmAccess(data) {
            const registerManager = this.getCRMRegistry.currentMenager
            const alertFrom = data.creator
            const previousProcessIndex = data.index
            const previousProcessId = data._id
            let success = await this.createCRMAccessAlert({
                id: this.$route.params.id,
                userId: registerManager._id,
                url: this.$route.name,
                alertFrom: alertFrom,
                alertTo: registerManager,
                previousProcessIndex: previousProcessIndex,
                previousProcessId: previousProcessId,
            })
            if (success) {
                this.closeAskAccessModal()
            }
        },

        openAskAccessModal() {
            this.openAskAccessModalBoolean = true
        },
        closeAskAccessModal() {
            this.openAskAccessModalBoolean = false
        },
        presentPreviousProcessWithTheClientDate(value) {
            if (value !== null) {
                return `${moment(value.createdAt).format('YYYY-MM-DD')} - ${
                    value.creator.name
                } ${value.creator.lastname}`
            }
            return ''
        },
        findRepresentative(item) {
            //add a period after each name
            const id = item.representative
            if (id) {
                const representative = item.contractor?.representatives?.find(
                    e => e._id === id
                )

                return `${this.$get(
                    representative,
                    'name',
                    'Brak'
                )} ${this.$get(representative, 'lastname', '')} ${
                    representative && representative.email ? '•' : ''
                } ${this.$get(representative, `email`, '')} ${
                    representative && representative.phoneNumber ? '•' : ''
                } ${this.$get(representative, 'phoneNumber', '')}`
            }
            return ''
        },
        convertBooleanToYesOrNo(value) {
            if (value === true) {
                return 'Tak'
            } else {
                return 'Nie'
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.participants-container
    margin-top:15px
    .header
        font-weight: bold
    .participants-inner
        display: flex
        gap: 4px
</style>
