<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="!validationResult"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getCRMDetails', 'getValidationSteps']),
        validationResult: {
            get() {
                const validationResultStepOne =
                    this.getValidationSteps.crmEventValidationStepOne || false
                return validationResultStepOne;
            },
        },
    },

    methods: {
        ...mapActions(['createNewEvent', 'fetchCRMRecord', 'editEvent']),
        
        async save() {
            const id = this.getCRMDetails._id
            let result = await this.createNewEvent(id)
            if (result) {
                this.$emit('closeModal')
                this.fetchCRMRecord({ id })
            }
        },
        async edit() {
            const id = this.getCRMDetails._id
            let result = await this.editEvent(id)

            if (result) {
                this.$emit('closeModal')
                this.fetchCRMRecord({ id })
            }
        },
    },
}
</script>
