var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getCRMStep.step),callback:function ($$v) {_vm.$set(_vm.getCRMStep, "step", $$v)},expression:"getCRMStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getCRMErrors.evaluationStepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v("Edycja")]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-3",attrs:{"text":'Edycja'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`Uwagi`,"rows":"10","auto-grow":""},model:{value:(
                                            _vm.getCRMModal.reportPage.comments
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMModal.reportPage, "comments", $$v)},expression:"\n                                            getCRMModal.reportPage.comments\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }