<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getCRMErrors.evaluationStepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('offers:customerRating')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                v-if="checkUserIsAvaliableManager()"
                                :rules="[
                                    () =>
                                        getCRMErrors.evaluationStepTwo
                                            .validation,
                                ]"
                                editable
                                step="2"
                                >{{ $t('offers:summary') }}</v-stepper-step
                            >
                            <v-divider
                                v-if="checkUserIsAvaliableManager()"
                            ></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator class="mb-3"
                                            :text="$t('offers:customerRating')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .devicesAmount
                                            "
                                            :label="`${$t(
                                                'offers:departments.devicesAmount'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .devicesLocation
                                            "
                                            :label="`${$t(
                                                'offers:departments.devicesLoc'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .websiteOpinion
                                            "
                                            :label="`${$t(
                                                'offers:departments.websiteOpinion'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .socialMedia
                                            "
                                            :label="`${$t(
                                                'offers:departments.socialMedia'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .emailFormat
                                            "
                                            :label="`${$t(
                                                'offers:departments.emailFormat'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            dense
                                            v-model="
                                                getCRMModal.evaluation
                                                    .customerShareCapital
                                            "
                                            :label="`${$t(
                                                'offers:departments.customerShareCapital'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getCRMModal.evaluation
                                                    .scopeActivities
                                            "
                                            :label="`${$t(
                                                'offers:departments.scopeActivities'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getCRMModal.evaluation.industry
                                            "
                                            :label="`${$t(
                                                'offers:departments.industry'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator class="mb-3"
                                            :text="$t('offers:summary')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="py-1">
                                        <!-- TO POLE DO EDYCJI TYLKO DLA MOZLIWYCH MENADZEROW -->
                                        <v-textarea
                                            dense
                                            v-if="checkUserIsAvaliableManager()"
                                            v-model="
                                                getCRMModal.evaluation.summary
                                            "
                                            :label="`${$t(
                                                'offers:departments.summary'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'getCRMModal',
            'getCRMErrors',
            'getCRMRegistry',
            'getProfileDetails',
            'getCRMStep',
        ]),
    },
    data() {
        return {}
    },
    methods: {
        checkUserIsAvaliableManager() {
            const userId = this.getProfileDetails._id
            const availableMenagersIds = this.getCRMRegistry.availableMenagers.map(
                x => x._id
            )
            if (
                availableMenagersIds.includes(userId) ||
                this.getProfileDetails.isAdmin
            ) {
                return true
            }

            return false
        },
    },
}
</script>

<style></style>
