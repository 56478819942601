<template>
    <div class="askContainer pa-5">
        <div class="py-5">
            <v-card-title class="justify-center pa-2">
                Prośba o uczestnictwo
            </v-card-title>
        </div>
        <img
            class="justify-center mb-3"
            width="100"
            height="100"
            src="@/assets/img/global/warning.svg"
            alt="warning"
        />
        <div class="text mt-4">
            <span
                >Potwierdź, że chcesz zostać uczestnikiem poprzedniego procesu.
            </span>Alert zostanie wysłany do managera CRM.
            
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.askContainer {
    display: grid;
    justify-content: center;
    justify-items: center;
    .text {
        
        display: grid;
        font-size: 20px;
        margin-top: 5px;
        grid-template-rows: 1fr;
    }
}
</style>
