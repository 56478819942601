var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getCRMStep.step),callback:function ($$v) {_vm.$set(_vm.getCRMStep, "step", $$v)},expression:"getCRMStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getCRMErrors.evaluationStepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v("Raport")]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-5",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-3",attrs:{"text":this.action}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"hidden-data",attrs:{"items":_vm.getCRMDetails.CRMParticipants,"label":`Pracownik`,"item-value":"_id","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))])]}},{key:"item",fn:function(item){return [_c('span',[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.item.lastname))])]}}]),model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.worker
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "worker", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.worker\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.crmReportDeviceSate
                                                ? _vm.getRegistryDictionaries.crmReportDeviceSate
                                                : [],"label":"Stan urządzenia","return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.deviceState
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "deviceState", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.deviceState\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"label":"Model","items":_vm.models,"return-object":"","item-text":_vm.comboBoxText,"item-value":"_id","variant":"solo"},on:{"keyup":function($event){return _vm.searchModel($event.target.value)}},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.model
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "model", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.model\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.subscriptionType
                                                ? _vm.getRegistryDictionaries.subscriptionType
                                                : [],"label":"Rodzaj","return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                .subscriptionType
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                , "subscriptionType", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem\n                                                .subscriptionType\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":`FTTR`,"return-object":"","items":[
                                            'Tak',
                                            'Nie',
                                            'Nie dotyczy',
                                        ]},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.fttrValue
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "fttrValue", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.fttrValue\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Numer umowy/FV"},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                .contractNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                , "contractNumber", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem\n                                                .contractNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.durationOfTheContractDictionary
                                                ? _vm.getRegistryDictionaries.durationOfTheContractDictionary
                                                : [],"label":"Czas trwania umowy","return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                .durationOfTheContract
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                , "durationOfTheContract", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem\n                                                .durationOfTheContract\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":`Jest aneksem`,"return-object":"","items":[
                                            'Tak',
                                            'Nie',
                                            'Nie dotyczy',
                                        ]},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.isAnnex
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "isAnnex", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.isAnnex\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Aneksuje umowę"},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                .blankingContract
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem
                                                , "blankingContract", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem\n                                                .blankingContract\n                                        "}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Uwagi"},model:{value:(
                                            _vm.getCRMNewReportItemModal
                                                .crmReportItem.comments
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMNewReportItemModal
                                                .crmReportItem, "comments", $$v)},expression:"\n                                            getCRMNewReportItemModal\n                                                .crmReportItem.comments\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }