var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('offers:customerRating')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6 "}},[_c('v-textarea',{staticStyle:{"width":"50%"},attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.devicesAmount',
                                ''
                            ),"label":_vm.$t('offers:departments.devicesAmount')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticStyle:{"width":"50%"},attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.devicesLocation',
                                ''
                            ),"label":_vm.$t('offers:departments.devicesLoc')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.websiteOpinion',
                                ''
                            ),"label":_vm.$t('offers:departments.websiteOpinion')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.socialMedia',
                                ''
                            ),"label":_vm.$t('offers:departments.socialMedia')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.emailFormat',
                                ''
                            ),"label":_vm.$t('offers:departments.emailFormat')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticStyle:{"width":"50%"},attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.customerShareCapital',
                                ''
                            ),"label":_vm.$t('offers:departments.customerShareCapital')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticStyle:{"width":"50%"},attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(
                                _vm.getCRMDetails,
                                'evaluation.scopeActivities',
                                ''
                            ),"label":_vm.$t('offers:departments.scopeActivities')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticStyle:{"width":"50%"},attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(_vm.getCRMDetails, 'evaluation.industry', ''),"label":_vm.$t('offers:departments.industry')}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('offers:summary')}})],1)],1),_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","readonly":"","value":_vm.$get(_vm.getCRMDetails, 'evaluation.summary', ''),"label":_vm.$t('offers:departments.summary')}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end buttons--details"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.edit())?_c('v-btn',_vm._g({staticClass:"buttons--add margin--1",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.editDeploys()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s('Edycja oceny potencjału'))])])],1),_c('Modal',{attrs:{"title":'Edycja oceny potencjału',"open":_vm.open,"height":'520'},on:{"close":function($event){return _vm.closeModal()}}},[_c('AEContent',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons","type":'CRM_EVALUATION',"action":'edit'},on:{"closeModal":function($event){_vm.open = false}},slot:"buttons"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }