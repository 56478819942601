<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCRMStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getCRMErrors.evaluationStepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >Edycja</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            class="mb-3"
                                            :text="'Edycja'"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="
                                                getCRMModal.reportPage.comments
                                            "
                                            :label="`Uwagi`"
                                            rows="10"
                                            auto-grow
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'getCRMModal',
            'getCRMErrors',
            'getCRMRegistry',
            'getProfileDetails',
            'getCRMStep',
        ]),
    },
    data() {
        return {
            expectationsDate: '',
            expectationsDate2: '',
        }
    },
    methods: {},
}
</script>

<style></style>
