var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getCRMStep.step),callback:function ($$v) {_vm.$set(_vm.getCRMStep, "step", $$v)},expression:"getCRMStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[() => _vm.getCRMErrors.stepOne.validation],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('offers:basicInfo')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[() => _vm.getCRMErrors.stepTwo.validation],"editable":"","step":"2"}},[_vm._v(_vm._s('Opisy'))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getCRMErrors.stepThree.validation,
                            ],"editable":"","step":"3"}},[_vm._v(_vm._s('Uczestnicy'))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-form',{ref:"editInformationValidationStepOne",model:{value:(
                                    _vm.getValidationSteps.editInformationValidationStepOne
                                ),callback:function ($$v) {_vm.$set(_vm.getValidationSteps, "editInformationValidationStepOne", $$v)},expression:"\n                                    getValidationSteps.editInformationValidationStepOne\n                                "}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-3",attrs:{"text":_vm.$t('offers:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","rules":_vm.requiredRules,"label":`${_vm.$t(
                                                'offers:title'
                                            )}*`},on:{"change":function($event){return _vm.validateEditInformationValidationStepOne()}},model:{value:(_vm.getCRMModal.title),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "title", $$v)},expression:"getCRMModal.title"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"rules":_vm.requiredRules,"dense":"","label":`${_vm.$t(
                                                'offers:contractor'
                                            )}*`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":"","error-messages":_vm.getCRMErrors.stepOne.fields
                                                    .contractor},on:{"keyup":function($event){return _vm.setContacts(
                                                    $event.target.value
                                                )},"input":function($event){return _vm.assignID()},"change":function($event){_vm.ifClientOperationLocked()
                                                _vm.validateEditInformationValidationStepOne()}},model:{value:(_vm.getCRMModal.contractor),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "contractor", $$v)},expression:"getCRMModal.contractor"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":_vm.requiredRules,"items":_vm.getRegistryDictionaries.crmOfferType
                                                    ? _vm.getRegistryDictionaries.crmOfferType
                                                    : [],"dense":"","label":_vm.$t('offers:offerKind'),"return-object":"","item-value":"field"},on:{"change":function($event){return _vm.validateEditInformationValidationStepOne()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getCRMModal.offerType),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "offerType", $$v)},expression:"getCRMModal.offerType"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":_vm.requiredRules,"items":_vm.getRegistryDictionaries.crmCustomerType
                                                    ? _vm.getRegistryDictionaries.crmCustomerType
                                                    : [],"dense":"","label":_vm.$t('offers:customerKind'),"return-object":"","item-value":"field"},on:{"change":function($event){return _vm.validateEditInformationValidationStepOne()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                                _vm.getCRMModal.customerType
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "customerType", $$v)},expression:"\n                                                getCRMModal.customerType\n                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"checkbox--lead",attrs:{"dense":"","label":'Lead'},model:{value:(
                                                _vm.getCRMModal.leadBoolean
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "leadBoolean", $$v)},expression:"\n                                                getCRMModal.leadBoolean\n                                            "}})],1),_c('v-col',{attrs:{"offset":"4","cols":"6"}},[_c('v-select',{attrs:{"dense":"","items":_vm.getRegistryDictionaries.crmEventPrognosis
                                                    ? _vm.getRegistryDictionaries.crmEventPrognosis
                                                    : [],"label":'Rokowanie',"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                        data.item
                                                            .backgroundColor
                                                    )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                                .backgroundColor,"small":"","label":"","text-color":data.item
                                                                .textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getCRMModal.prognosis),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "prognosis", $$v)},expression:"getCRMModal.prognosis"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset":"6","cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getCRMErrors.stepOne.fields
                                                    .sourceOfAcquisition,"label":'Źródło pozyskania'},model:{value:(
                                                _vm.getCRMModal.sourceOfAcquisition
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "sourceOfAcquisition", $$v)},expression:"\n                                                getCRMModal.sourceOfAcquisition\n                                            "}})],1)],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-1",attrs:{"text":'Opis'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`Prognozowanie`},model:{value:(_vm.getCRMModal.forecasting),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "forecasting", $$v)},expression:"getCRMModal.forecasting"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`Zagrożenia`},model:{value:(_vm.getCRMModal.threats),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "threats", $$v)},expression:"getCRMModal.threats"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`Obecny dostawca`},model:{value:(
                                            _vm.getCRMModal.currentSupplier
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "currentSupplier", $$v)},expression:"\n                                            getCRMModal.currentSupplier\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","label":`Ocena końcowa procesu`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(
                                            _vm.getCRMModal.finalEvaluationOfTheProcess
                                        ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "finalEvaluationOfTheProcess", $$v)},expression:"\n                                            getCRMModal.finalEvaluationOfTheProcess\n                                        "}})],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{staticClass:"mb-6",attrs:{"text":'Uczestnicy'}})],1)],1),_c('v-form',{ref:"editInformationValidationStepThree",model:{value:(
                                    _vm.getValidationSteps.editInformationValidationStepThree
                                ),callback:function ($$v) {_vm.$set(_vm.getValidationSteps, "editInformationValidationStepThree", $$v)},expression:"\n                                    getValidationSteps.editInformationValidationStepThree\n                                "}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-flex',{staticClass:"select",attrs:{"shrink":""}},[_c('v-select',{staticClass:"hidden-data",attrs:{"rules":[_vm.validateArrayRequired],"items":_vm.possibleCRMParticipants,"multiple":"","dense":"","label":`Możliwi uczestnicy`,"item-value":"_id","return-object":""},on:{"change":function($event){return _vm.validateEditInformationValidationStepThree()}},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))]),(
                                                        _vm.getCRMModal
                                                            .CRMParticipants
                                                            .length > 1
                                                    )?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]}},{key:"item",fn:function(item){return [_c('span',[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.item.lastname))])]}}]),model:{value:(
                                                _vm.getCRMModal.CRMParticipants
                                            ),callback:function ($$v) {_vm.$set(_vm.getCRMModal, "CRMParticipants", $$v)},expression:"\n                                                getCRMModal.CRMParticipants\n                                            "}})],1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }